<template>
  <b-row>
    <b-col cols="12" v-if="can('read/manajemen-sppd')">
      <b-card title="Pengajuan SPPD">
        <b-row>
          <b-col class="mb-1">
            <b-button
              v-if="can('store/manajemen-sppd')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-0"
              @click="gotoCreatePage()"
            >
              Tambah
            </b-button>
          </b-col>
        </b-row>

        <b-row class="pt-1 mb-1">
          <b-col md="8">
            <b-button-group class="d-none d-lg-inline-flex">
              <b-button
                v-for="{ text, value } in filterOptions"
                :key="value"
                :variant="filter === value ? 'primary' : 'outline-primary'"
                @click="filter = value"
              >
                {{ text }}
              </b-button>
            </b-button-group>
            <div class="d-block d-lg-none mb-1">
              <b-form-select
                v-model="filter"
                :options="filterOptions"
              ></b-form-select>
            </div>
          </b-col>

          <b-col md="4">
            <b-input-group size="8">
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="'Cari disini...'"
              />
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-table
          ref="tableFe"
          striped
          hover
          responsive
          show-empty
          class="position-relative d-none d-md-block"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :sort-by.sync="sortBy"
          :fields="fields"
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(sppd_number)="data">
            <span v-b-popover.hover.bottom="data.item.sppd_number">
              {{ data.item.sppd_number.substr(0, 10) }}...
            </span>
          </template>

          <template #cell(name)="data">
            {{
              data.item.employee
                ? data.item.employee.employee + ' / ' + data.item.employee.name
                : '-'
            }}
          </template>

          <template #cell(total_days)="data">
            {{ data.item.total_days ? `${data.item.total_days} hari` : '-' }}
          </template>

          <template #cell(transportation)="data">
            {{ transportations[data.item.transportation] }}
          </template>

          <template #cell(destination_office)="data">
            {{ data.item.destination_office || '-' }}
          </template>

          <template #cell(facility)="data">
            <template v-if="data.item.sppd_facility_type_id == 1">
              Perjalanan Dinas Biasa
            </template>
            <template v-else-if="data.item.sppd_facility_type_id == 2">
              Perjalanan Dinas Fasilitas Khusus
            </template>
            <template v-else>-</template>
          </template>

          <template #cell(submission_date)="data">
            {{
              data.item.is_draft
                ? '-'
                : data.item.submission_date | moment('DD-MM-YYYY')
            }}
          </template>

          <template #cell(permission_letter)="data">
            {{
              data.item.permission_letter
                ? `${data.item.permission_letter.substr(0, 10)}...`
                : 0 > data.item.day_remainder
                ? 'Belum Upload'
                : '-'
            }}
          </template>

          <template #cell(general_status)="data">
            {{ data.item.general_status || '-' }}
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div style="min-width: 220px">
              <b-button
                v-if="data.item.is_draft && can('apply/manajemen-sppd')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Kirim'"
                variant="success"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="ajukan(data.item.id)"
              >
                <feather-icon icon="SendIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.general_status.toLowerCase() ===
                    'menunggu approval 1' ||
                  data.item.general_status.toLowerCase() ===
                    'menunggu approval 2' ||
                  data.item.general_status.toLowerCase() === 'lengkap' ||
                  (data.item.general_status.toLowerCase() ===
                    'sudah melakukan perjalanan' &&
                    can('print/manajemen-sppd'))
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Print'"
                variant="primary"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="print(data.item.id, data.item.sppd_number)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.general_status.toLowerCase() === 'dibatalkan' &&
                  can('info/manajemen-sppd')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Info'"
                v-b-modal.modal-cancel-reason
                variant="secondary"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="cancelReason = data.item.reason"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>

              <b-button
                v-if="can('show/manajemen-sppd')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Detail'"
                variant="info"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoDetailPage(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.general_status.toLowerCase() ===
                    'menunggu approval 1' &&
                  (user.nippos == data.item.submitter_id ||
                    user.nik == data.item.submitter_id)
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Approve 1'"
                variant="success"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="confirmApprove(data.item.id, 1)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="
                  (data.item.general_status.toLowerCase() ===
                    'menunggu approval 2' ||
                    (data.item.general_status.toLowerCase() ===
                      'sudah melakukan perjalanan' &&
                      !data.item.approve2)) &&
                  (user.nippos == data.item.instructor_id ||
                    user.nik == data.item.instructor_id)
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Approve 2'"
                variant="success"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="confirmApprove(data.item.id, 2)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="data.item.is_draft && can('edit/manajemen-sppd')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Ubah'"
                variant="warning"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoEditPage(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                v-if="data.item.is_draft && can('delete/manajemen-sppd')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Hapus'"
                variant="danger"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              <b-dropdown
                v-if="
                  (data.item.general_status.toLowerCase() ===
                    'menunggu approval 1' ||
                    data.item.general_status.toLowerCase() ===
                      'menunggu approval 2' ||
                    (data.item.general_status.toLowerCase() === 'lengkap' &&
                      can('update_status/manajemen-sppd')) ||
                    data.item.employee.employee === user.nippos ||
                    data.item.employee.employee === user.nik) &&
                  data.item.general_status.toLowerCase() !==
                    'sudah melakukan perjalanan' &&
                  data.item.general_status.toLowerCase() !== 'dibatalkan'
                "
                size="sm"
                toggle-class="text-decoration-none bg-secondary p-0 btn-status"
                no-caret
              >
                <template #button-content>
                  <feather-icon icon="ChevronDownIcon" />
                </template>
                <b-dropdown-item
                  v-if="showUpdateMelakukanPerjalanan(data.item)"
                  @click="updateStatusSppd(data.item.id, 1)"
                >
                  Sudah Melakukan Perjalanan Dinas
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-cancel-sppd
                  @click="selectedSppd = data.item.id"
                >
                  Batalkan
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <!-- Mobile Table -->
        <b-table
          ref="tableFeMobile"
          striped
          hover
          responsive
          show-empty
          class="position-static d-block d-md-none"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :sort-by.sync="sortBy"
          :fields="mobileFields"
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(sppd_number)="data">
            <span v-b-popover.hover.bottom="data.item.sppd_number">
              {{ data.item.sppd_number.substr(0, 10) }}...
            </span>
          </template>

          <template #cell(name)="data">
            {{
              data.item.employee
                ? data.item.employee.employee + ' / ' + data.item.employee.name
                : '-'
            }}
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <b-dropdown text="Aksi" size="sm" class="position-static">
              <b-dropdown-item
                v-if="data.item.is_draft && can('apply/manajemen-sppd')"
                @click="ajukan(data.item.id)"
              >
                <feather-icon icon="SendIcon" />
                <label style="margin-left: 8px">Kirim</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  data.item.general_status.toLowerCase() ===
                    'menunggu approval 1' ||
                  data.item.general_status.toLowerCase() ===
                    'menunggu approval 2' ||
                  data.item.general_status.toLowerCase() === 'lengkap' ||
                  (data.item.general_status.toLowerCase() ===
                    'sudah melakukan perjalanan' &&
                    can('print/manajemen-sppd'))
                "
                @click="print(data.item.id, data.item.sppd_number)"
              >
                <feather-icon icon="PrinterIcon" />
                <label style="margin-left: 8px">Print</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  data.item.general_status.toLowerCase() === 'dibatalkan' &&
                  can('info/manajemen-sppd')
                "
                v-b-modal.modal-cancel-reason
                @click="cancelReason = data.item.reason"
              >
                <feather-icon icon="InfoIcon" />
                <label style="margin-left: 8px">Info</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="can('show/manajemen-sppd')"
                @click="gotoDetailPage(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
                <label style="margin-left: 8px">Detail</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  data.item.general_status.toLowerCase() ===
                    'menunggu approval 1' &&
                  (user.nippos == data.item.submitter_id ||
                    user.nik == data.item.submitter_id)
                "
                @click="confirmApprove(data.item.id, 1)"
              >
                <feather-icon icon="CheckIcon" />
                <label style="margin-left: 8px">Approve 1</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  (data.item.general_status.toLowerCase() ===
                    'menunggu approval 2' ||
                    (data.item.general_status.toLowerCase() ===
                      'sudah melakukan perjalanan' &&
                      !data.item.approve2)) &&
                  (user.nippos == data.item.instructor_id ||
                    user.nik == data.item.instructor_id)
                "
                @click="confirmApprove(data.item.id, 2)"
              >
                <feather-icon icon="CheckIcon" />
                <label style="margin-left: 8px">Approve 2</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.is_draft && can('edit/manajemen-sppd')"
                @click="gotoEditPage(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
                <label style="margin-left: 8px">Edit</label>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.is_draft && can('delete/manajemen-sppd')"
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <label style="margin-left: 8px">Hapus</label>
              </b-dropdown-item>
              <template
                v-if="
                  (data.item.general_status.toLowerCase() ===
                    'menunggu approval 1' ||
                    data.item.general_status.toLowerCase() ===
                      'menunggu approval 2' ||
                    (data.item.general_status.toLowerCase() === 'lengkap' &&
                      can('update_status/manajemen-sppd')) ||
                    data.item.employee.employee === user.nippos ||
                    data.item.employee.employee === user.nik) &&
                  data.item.general_status.toLowerCase() !==
                    'sudah melakukan perjalanan' &&
                  data.item.general_status.toLowerCase() !== 'dibatalkan'
                "
              >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item
                  v-if="showUpdateMelakukanPerjalanan(data.item)"
                  @click="updateStatusSppd(data.item.id, 1)"
                >
                  Sudah Melakukan Perjalanan Dinas
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-cancel-sppd
                  @click="selectedSppd = data.item.id"
                >
                  Batalkan
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>
        </b-table>

        <!-- Modal Batalkan SPPD -->
        <b-modal
          id="modal-cancel-sppd"
          title="Batalkan SPPD"
          ok-title="Ya"
          cancel-title="Tidak"
          cancel-variant="outline-secondary"
          centered
          hide-header-close
          @ok="handleCancelSppd"
        >
          <b-form>
            <b-form-group
              label="Masukkan alasan mengapa Anda membatalkan SPPD tersebut?"
              label-for="reason"
            >
              <b-form-textarea
                id="reason"
                v-model="cancelReason"
                placeholder="Ketikkan alasan..."
                rows="3"
                max-rows="6"
                autofocus
              />
            </b-form-group>
          </b-form>
        </b-modal>

        <!-- Modal Alasan Batalkan SPPD -->
        <b-modal
          id="modal-cancel-reason"
          title="Alasan Dibatalkan"
          centered
          hide-footer
          @hidden="resetCancelSppd"
        >
          <b-form>
            <b-form-group>
              <b-form-textarea
                rows="3"
                max-rows="6"
                disabled
                :value="cancelReason"
              />
            </b-form-group>
          </b-form>
        </b-modal>

        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: 'red !important';
}
</style>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BPagination,
  VBPopover,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import debounce from 'debounce'

import { getUserData } from '@/auth/utils'

export default {
  name: 'SppdList',
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BPagination,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/sppd',
      },
      user: getUserData(),
      search: '',
      filterOptions: [
        { value: '', text: 'Semua' },
        { value: 'draft', text: 'Draft' },
        { value: 'lengkap', text: 'Lengkap' },
        { value: 'approve1', text: 'Approval 1' },
        { value: 'approve2', text: 'Approval 2' },
        { value: 'dibatalkan', text: 'Dibatalkan' },
      ],
      filter: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      mobileFields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:200px' },
        { key: 'Aksi', label: 'Aksi' },
      ],
      fields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:200px' },
        { key: 'total_days', label: 'Lama Perjalanan' },
        { key: 'destination_office', label: 'Tujuan' },
        { key: 'sppd_type', label: 'Jenis Perjalanan Dinas' },
        { key: 'facility', label: 'Fasilitas' },
        { key: 'transportation', label: 'Alat Transportasi' },
        { key: 'submission_date', label: 'Tanggal Pengajuan' },
        { key: 'permission_letter', label: 'Surat Izin' },
        { key: 'general_status', label: 'Status' },
        { key: 'Aksi', label: 'Aksi', thStyle: 'width: 220px' },
      ],
      items: [],
      cancelReason: '',
      selectedSppd: null,
      transportations: ['-', 'Dinas', 'Pribadi', 'Umum'],
    }
  },
  watch: {
    filter() {
      this.$refs.tableFe.refresh()
      this.$refs.tableFeMobile.refresh()
    },
    search: debounce(function () {
      this.$refs.tableFe.refresh()
      this.$refs.tableFeMobile.refresh()
    }, 300),
  },
  mounted() {
    if (this.$route.params) {
      if (this.$route.params.activeTab) {
        this.filter = this.$route.params.activeTab
      }

      if (this.$route.params.event) {
        this.makeToast(
          this.$route.params.event,
          this.$route.params.title,
          this.$route.params.text,
        )
      }
    }
  },
  methods: {
    gotoDetailPage(id) {
      const _ = this
      _.$router.push(`/manajemen-sppd/${id}/detail`)
    },
    getItems(ctx, callback) {
      const _ = this
      // let filter = ctx.filter ? '&key=' + ctx.filter : ''
      // const orderBy = ctx.sortDesc ? 'asc' : 'desc'
      // const spliting = ctx.sortBy.split('.')
      // let sortBy = spliting[spliting.length - 1]
      // if (sortBy.includes('_cast')) {
      //   sortBy = sortBy.split('_')[0]
      // }
      // sortBy = sortBy != '' ? sortBy : 'id'
      axios
        .get(
          `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}&search=${_.search}&filter=${_.filter}`,
        )
        .then(resp => {
          const consume = resp.data.data
          _.perPage = consume.per_page
          _.currentPage = consume.current_page
          _.totalRows = consume.total
          callback(consume.data || [])
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
      return null
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    gotoCreatePage() {
      const _ = this
      _.$router.push('/manajemen-sppd/add')
    },
    gotoEditPage(id) {
      const _ = this
      _.$router.push(`/manajemen-sppd/${id}/edit`)
    },
    confirmApprove(id, approveType) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui SPPD tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const url = `${this.config.api}/approve_${approveType}`
          const data = { id }
          axios
            .post(url, data)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Disetujui',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$refs.tableFe.refresh()
              this.$refs.tableFeMobile.refresh()
            })
            .catch(err => {
              this.$swal({
                icon: 'Danger',
                title: 'Terjadi kesalahan!',
                text:
                  err.response?.status === 422
                    ? err.response?.data.message
                    : err.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    resetCancelSppd() {
      this.cancelReason = ''
      this.selectedSppd = null
    },
    updateStatusSppd(id, status, reason = null) {
      // 1 = SUDAH_MELAKUKAN_PERJALANAN, 2 = DIBATALKAN
      const data = {
        id,
        status,
        reason,
      }
      axios
        .post(`${this.config.api}/change_status`, data)
        .then(() => {
          this.makeToast('success', 'Update Status', 'Ubah status berhasil')
          this.resetCancelSppd()
          this.$refs.tableFe.refresh()
          this.$refs.tableFeMobile.refresh()
        })
        .catch(err => {
          this.makeToast('danger', 'Terjadi kesalahan', err.message)
        })
    },
    handleCancelSppd() {
      this.updateStatusSppd(this.selectedSppd, 2, this.cancelReason)
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${this.config.api}/${id}`)
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Dihapus',
                text: response.data.data.messages,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$refs.tableFe.refresh()
              this.$refs.tableFeMobile.refresh()
            })
            .catch(err => {
              this.$swal({
                icon: 'Danger',
                title: 'Tidak bisa di hapus',
                text: err.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    ajukan(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk mengajukan SPPD tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = { id, is_draft: false }
          axios
            .patch(this.config.api, data)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Diajukan',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$refs.tableFe.refresh()
              this.$refs.tableFeMobile.refresh()
            })
            .catch(err => {
              this.$swal({
                icon: 'Danger',
                title: 'Terjadi kesalahan!',
                text: err.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    print(id, sppdNumber) {
      axios
        .get(`${this.config.api}/print/${id}`, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `SPPD-${sppdNumber}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    },
    showUpdateMelakukanPerjalanan(item) {
      return (
        (item?.employee.type === 'pegawai'
          ? item?.employee.employee === this.user.nippos ||
            item?.employee.employee === this.user.nik
          : this.user.nippos == item?.submitter_id ||
            this.user.nik == item?.submitter_id ||
            this.user.nippos == item?.instructor_id ||
            this.user.nik == item?.instructor_id ||
            this.user.role === 'admin') &&
        item?.general_status.toLowerCase() === 'lengkap'
      )
    },
  },
}
</script>

<style>
.b-popover {
  font-size: 10px;
}
.btn-status {
  width: 30px;
  height: 30px;
}
</style>
